<template>
  <v-card class="card mb-6 px-9 pb-4">
    <v-card-title>
      <IconedText
        :isIcon="question.isDone"
        icon="mdi-checkbox-marked-circle"
        :text="question.title"
        iconColor="green"
        :top="3"
      />
    </v-card-title>
    <v-card-text>
      <div v-if="question.answerId">
        <div>
          <span>Ваш ответ: </span>
          <span class="font-weight-bold mr-4">{{ answerName }}</span>
          <span class="change-answer" @click="onAnswer(null)"
            >Изменить ответ</span
          >
        </div>
        <div class="comment-row" v-if="dispalayCommentInSummary">
          <span v-if="files.length" class="attachment-icon"
            ><v-icon>mdi-attachment</v-icon></span
          >
          <span>Комментарий: </span>
          <span class="font-weight-bold mr-4 comment">{{
            question.comment
          }}</span>
          <span class="change-answer" @click="onChangeComment">{{
            changeCommentText
          }}</span>
        </div>
      </div>
      <div v-else>
        <v-btn
          v-for="btn in answersForTemplate"
          :key="btn.id"
          outlined
          class="mr-4"
          @click="onAnswer(btn.id)"
          >{{ btn.text }}</v-btn
        >
      </div>
      <div v-if="displayCommentTextarea">
        <div class="place-for-alert">
          <IconedText
            v-if="!text"
            :isIcon="true"
            icon="mdi-alert-circle"
            :text="noText"
            iconColor="amber"
            :top="-3"
          />
        </div>
        <v-textarea outlined v-model="text"></v-textarea>
        <SelectFiles
          :files="files"
          :stagedFiles="stagedFiles"
          @files="onFilesChange"
          @staged-files="onStagedFilesChange"
          :edit="true"
          class="mb-2"
        />
        <v-btn color="primary" @click="onSaveText" :disabled="!text"
          >Сохранить</v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import IconedText from '@/components/expert/IconedText.vue'
import { answers, NO_ANSWER_ID } from '@/store/const/answers'
import SelectFiles from '@/components/dialogs/SelectFiles.vue'

export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconedText,
    SelectFiles,
  },
  data() {
    return {
      text: null,
      edit: false,
      files: [],
      stagedFiles: [],
      noText:
        'К ответу «нет» обязательно нужен комментарий. В комментарии кратко напишите с чем вы не согласны. Если нужно укажите номера тезис-рекомендаций. Можете прикрепить файл с предложениями в формате excel или word.',
    }
  },
  created() {
    this.prepareFiles()
  },
  watch: {
    question: {
      deep: true,
      handler() {
        this.prepareFiles()
      },
    },
  },
  computed: {
    displaySummary() {
      return this.question.answerId
    },
    answersForTemplate() {
      return answers
    },
    displayButtons() {
      return !this.displaySummary
    },
    dispalayCommentInSummary() {
      if (this.edit) return false
      return this.question.answerId === NO_ANSWER_ID && this.question.comment
    },
    displayCommentTextarea() {
      if (this.edit) return true
      if (this.question.answerId === NO_ANSWER_ID && !this.question.comment)
        return true
      return false
    },
    answerName() {
      return answers.find(d => d.id === this.question.answerId).text
    },
    changeCommentText() {
      return this.files.length
        ? 'Изменить комментрий или файлы'
        : 'Изменить комментрий или добавить файл'
    },
  },
  methods: {
    prepareFiles() {
      this.files = this.question.files.slice()
    },
    onAnswer(answerId) {
      this.edit = false
      this.$emit('on-answer', { id: this.question.id, answerId })
    },
    onChangeComment() {
      this.edit = true
      this.text = this.question.comment
    },
    onSaveText() {
      this.edit = false
      this.$emit('on-save-text', { id: this.question.id, text: this.text })
      if (this.stagedFiles.length) {
        this.$emit('on-change-question-files', {
          id: this.question.id,
          files: this.stagedFiles.slice(),
        })
        this.stagedFiles = []
      }
    },
    onFilesChange(value) {
      this.files = value
    },
    onStagedFilesChange(value) {
      this.stagedFiles = value
    },
  },
}
</script>

<style scoped>
.v-card__text {
  min-height: 62px;
}
.v-card__title {
  word-break: normal;
}
.change-answer {
  color: grey;
  border-bottom: 1px dashed grey;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.15s ease;
}
.change-answer:hover {
  opacity: 0.8;
}
.place-for-alert {
  min-height: 26px;
}
</style>
