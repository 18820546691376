<template>
  <Page>
    <div v-if="isLoading && !clinRec.id">КР Загружается</div>
    <QuestionPage v-else :clinRec="clinRec" />
  </Page>
</template>

<script>
import Page from '@/components/layout/Page.vue'
import QuestionPage from '@/components/expert/QuestionPage.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    QuestionPage,
    Page,
  },
  created() {
    this.load(this.clinRecId)
  },
  computed: {
    ...mapGetters({
      clinRec: 'getCurrentClinRec',
      isLoading: 'isClinRecLoading',
    }),
    clinRecId() {
      return Number(this.$route.params.clinRecId)
    },
  },
  methods: {
    ...mapActions({ load: 'loadClinRecById' }),
  },
}
</script>
