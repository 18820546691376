<template>
  <div class="wrapper ml-n8">
    <v-icon v-if="isIcon" :class="colorClass" :style="topStyle">{{
      icon
    }}</v-icon>
    <div class="pl-8">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: 'grey',
    },
    top: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    colorClass() {
      return `${this.iconColor}--text`
    },
    topStyle() {
      return this.top ? `top: ${this.top}px;` : 'top: 0;'
    },
  },
}
</script>

<style scoped>
.wrapper {
  position: relative;
}
.v-icon {
  position: absolute !important;
}
</style>
