<template>
  <v-data-table
    :items="tableData"
    :headers="headers"
    hide-default-footer
    disable-pagination
  >
    <template v-slot:[`item.files`]="{ item }">
      <FileList v-if="item.files.length" :files="item.files" />
      <div v-else>—</div>
    </template>
  </v-data-table>
</template>

<script>
import FileList from '@/components/cards/FileList.vue'
import { typograpf } from '@/components/helpers'
import { questionsList } from '@/store/const/questionsList'
import { answers } from '@/store/const/answers'

export default {
  components: { FileList },
  props: {
    clinRec: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: '№', value: 'number', sortable: false },
        { text: 'Вопрос', value: 'title', sortable: false },
        { text: 'Ответ', value: 'answer', sortable: false },
        { text: 'Файлы', value: 'files', sortable: false },
        { text: 'Комментрий', value: 'comment', sortable: false },
      ],
    }
  },
  computed: {
    tableData() {
      return this.inquirer.data.map((q, index) => ({
        ...q,
        number: index + 1,
        title: this.getQuestionTitle(q),
        files: this.getQuestionFiles(q),
        answer: this.getAnswer(q),
      }))
    },
    /*
      TODO
      Можно было бы убрать совсем (используется всего два раза)
      Но с ним getQuestionFiles выглядит единообразнее (или вынести в тотже миксин)
    */
    inquirer() {
      return this.clinRec.inquirer
    },
  },
  methods: {
    getAnswer(q) {
      return answers.find(d => d.id === q.answerId).text
    },
    /*
      TODO getQuestionTitle и getQuestionFiles вместе с их обвесом можно вынести в mixin
      Они повторяются как минимум в QuestionPage ровно в таком же виде
    */
    getQuestionTitle(q) {
      return typograpf(
        questionsList[this.clinRec.profile].find(t => t.id === q.id).title
      )
    },
    getQuestionFiles(q) {
      const files = []
      q.filesIds.forEach(fid => {
        files.push(this.inquirer.files.find(file => file.id === fid))
      })
      return files
    },
  },
}
</script>
