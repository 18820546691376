<template>
  <div>
    <QuestionPageHeader class="mb-8" :clinRec="clinRec" :wasSent="wasSent" />

    <div v-if="wasSent" class="mb-8">
      <div>Вы уже ответили на эту анкету. Вот ваши ответы:</div>
      <ReadyAnswersForExpert :clinRec="clinRec" />
    </div>

    <div v-else>
      <div class="height-holder mb-8">
        <SendAnswers
          v-if="isReady"
          :wasSent="wasSent"
          @on-finalize="handleFinalize"
        />
        <ProgressBar v-else :ready="ready" :total="total" />
      </div>

      <QuestionCard
        v-for="q in preparedQuestions"
        :key="q.id"
        :question="q"
        @on-save-text="handleSaveText"
        @on-answer="handleAnswer"
        @on-change-question-files="handleFiles"
      />

      <div class="height-holder mb-8">
        <SendAnswers
          v-if="isReady"
          :wasSent="wasSent"
          @on-finalize="handleFinalize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { isQuestionDone, typograpf, copyObject } from '@/components/helpers'
import { questionsList } from '@/store/const/questionsList'
import { NO_ANSWER_ID } from '@/store/const/answers'
import QuestionCard from '@/components/expert/QuestionCard.vue'
import ProgressBar from '@/components/expert/ProgressBar.vue'
import QuestionPageHeader from '@/components/expert/QuestionPageHeader.vue'
import SendAnswers from '@/components/expert/SendAnswers.vue'
import ReadyAnswersForExpert from '@/components/expert/ReadyAnswersForExpert.vue'

export default {
  props: ['clinRec'],
  mounted() {
    document.title = 'Анкета: ' + this.clinRec.id
  },
  computed: {
    inquirer() {
      return this.clinRec.inquirer
    },
    wasSent() {
      return this.inquirer.was_sent
    },
    questions() {
      return this.inquirer.data
    },
    total() {
      return this.preparedQuestions.length
    },
    ready() {
      return this.preparedQuestions.filter(q => q.isDone).length
    },
    isReady() {
      return this.ready === this.total
    },
    preparedQuestions() {
      return this.questions.map(q => ({
        ...q,
        isDone: isQuestionDone(q),
        title: this.getQuestionTitle(q),
        files: this.getQuestionFiles(q),
      }))
    },
  },
  methods: {
    ...mapActions(['editQuestionnaire', 'updateQuestionFiles', 'deleteFile']),
    getQuestionFiles(q) {
      const files = []
      q.filesIds.forEach(fid => {
        files.push(this.inquirer.files.find(file => file.id === fid))
      })
      return files
    },
    getQuestionTitle(q) {
      return typograpf(
        questionsList[this.clinRec.profile].find(t => t.id === q.id).title
      )
    },
    handleAnswer({ id, answerId }) {
      const questions = copyObject(this.questions)
      questions.find(q => q.id === id).answerId = answerId
      this.editQuestionnaire({
        id: this.inquirer.id,
        data: questions,
        clinRecId: this.clinRec.id,
      })
    },
    handleSaveText({ id, text }) {
      const questions = copyObject(this.questions)
      questions.find(q => q.id === id).comment = text
      this.editQuestionnaire({
        id: this.inquirer.id,
        data: questions,
        clinRecId: this.clinRec.id,
      })
    },
    handleFiles({ id, files }) {
      this.updateQuestionFiles({
        inquirerId: this.inquirer.id,
        questionId: id,
        clinRecId: this.clinRec.id,
        files,
      })
    },
    handleFinalize() {
      this.editQuestionnaire({
        id: this.inquirer.id,
        was_sent: true,
        clinRecId: this.clinRec.id,
        data: this.clearQuestions(this.questions),
      })
    },
    clearQuestions() {
      const questions = copyObject(this.questions)
      questions.forEach(q => {
        if (q.answerId !== NO_ANSWER_ID) {
          q.comment = null
          while (q.filesIds.length) {
            this.deleteFile(q.filesIds.pop())
          }
        }
      })
      return questions
    },
  },
  components: {
    QuestionCard,
    ProgressBar,
    QuestionPageHeader,
    SendAnswers,
    ReadyAnswersForExpert,
  },
}
</script>

<style scoped>
.height-holder {
  min-height: 92px;
}
</style>
