<template>
  <div>
    <div class="success-text mb-2">
      <div>{{ actionText }}</div>
      <div>
        Жмите «Отправить», но будьте внимательны! После того как отправите,
        больше не сможете редактировать ответы.
      </div>
    </div>
    <v-btn class="" color="primary" @click="send">Отправить</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    wasSent: {
      required: true,
    },
  },
  computed: {
    actionText() {
      return this.wasSent === 0
        ? 'Администратор поменял файлы в карточке КР. Проверьте согласны ли вы с изменениями и отправьте анкету заново.'
        : 'Ура! Анкета завершена. Осталось только отправить.'
    },
  },
  methods: {
    send() {
      this.$emit('on-finalize')
    },
  },
}
</script>
