<template>
  <div class="progress-bar">
    <v-progress-linear
      class="mt-4 mb-2"
      :value="readyPercent"
    ></v-progress-linear>
    <span>Готово </span>
    <span class="font-weight-bold">{{ readyPercent }} </span>
    <span class="grey--text">({{ ready }} из {{ total }})</span>
  </div>
</template>

<script>
import { formatPercent } from '@/components/helpers'

export default {
  props: {
    ready: {
      type: Number,
      require: true,
    },
    total: {
      type: Number,
      require: true,
    },
  },
  computed: {
    readyPercent() {
      return formatPercent(this.ready / this.total)
    },
  },
}
</script>
