<template>
  <div>
    <h1 class="text-h2 mb-8 header-text-wrap">Анкета: {{ clinRec.name }}</h1>

    <div class="d-flex align-center">
      <v-btn @click="goBack" text class="ml-n4 align-self-center"
        >← Назад к списку КР</v-btn
      >

      <v-divider vertical class="ml-2 mr-6"></v-divider>

      <ClinRecCard
        :clinRec="clinRec"
        :showDeadlineAlert="!wasSent"
        :showDeadlineWithDays="!wasSent"
      />
    </div>
  </div>
</template>

<script>
import ClinRecCard from '@/components/cards/ClinRecCard.vue'

export default {
  props: {
    clinRec: {
      type: Object,
      required: true,
    },
    wasSent: {
      required: true,
    },
  },
  components: { ClinRecCard },
  methods: {
    goBack() {
      this.$router.push('/expert')
    },
  },
}
</script>

<style scope>
.header-text-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
